import React, { useCallback, useState } from 'react';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/misc/seo';
import ContactForm from 'src/components/widget/contact-form';
import Container from 'src/components/widget/container';
import PageHeader from 'src/components/widget/page-header';
import WideContainer from 'src/components/widget/wide-container';
import { StaticContextType } from 'typings/custom';
import {
  IContactUsPage,
  IMiscStaticContent,
} from 'typings/generated/contentful';

type Props = {
  pageContext: {
    reactContext: StaticContextType;
    page: IContactUsPage;
    misc: {
      success: IMiscStaticContent;
      error: IMiscStaticContent;
    };
  };
};

const ContactPage = ({ pageContext }: Props) => {
  const {
    reactContext,
    page: {
      fields: { title, description },
    },
    misc: { success: successContent, error: errorContent },
  } = pageContext;
  const [submitSuccess, setSubmitSuccess] = useState<true | false | null>(null);
  const [submitProgress, setSubmitProgress] = useState(false);

  const handleSubmit = useCallback((data) => {
    setSubmitProgress(true);
    fetch(process.env.GATSBY_CONTACT_FORM_MAILER_URL as string, {
      method: 'post',
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(({ success }) => setSubmitSuccess(success))
      .catch(() => setSubmitSuccess(false))
      .finally(() => setSubmitProgress(false));
  }, []);

  const renderForm = useCallback(
    () => (
      <>
        <PageHeader description={description} title={title} />
        <WideContainer>
          <ContactForm
            error={submitSuccess === false && errorContent.fields.title}
            onSubmit={handleSubmit}
            progress={submitProgress}
          />
        </WideContainer>
      </>
    ),
    [submitSuccess, submitProgress]
  );

  const renderSuccess = useCallback(
    () => (
      <div className="flex flex-col py-4 space-y-4 text-xl text-center text-white rounded bg-x-green-neon title">
        <span>{successContent.fields.title}</span>
        <a aria-label="Home" href="/">
          <button className="px-4 py-2 bg-white rounded text-x-green-neon">
            {successContent.fields.button1}
          </button>
        </a>
      </div>
    ),
    []
  );

  return (
    <Layout context={reactContext}>
      <SEO description={description} title={title} />
      <div className="flex flex-col items-center -mb-4 md:mb-0">
        <Container>
          {submitSuccess && renderSuccess()}
          {!submitSuccess && renderForm()}
        </Container>
      </div>
    </Layout>
  );
};

export default ContactPage;
