import cx from 'classnames';
import React, { FormEvent, useState } from 'react';
import useTranslation from 'src/hooks/useTranslation';

type ContactFormFields = {
  name: string;
  email: string;
  message: string;
};

type Props = {
  error: string | false | undefined;
  progress: boolean;
  onSubmit: (formData: ContactFormFields) => void;
};

const ContactForm = ({ error, progress, onSubmit }: Props) => {
  const t = useTranslation();
  const [message, setMessage] = useState('');
  const sendEnabled = !!message && !progress;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (document.getElementById('contact_form__email') as any).value;
    const name = (document.getElementById('contact_form__name') as any).value;
    const message = (document.getElementById('contact_form__message') as any)
      .value;
    onSubmit({ email, name, message });
  };

  return (
    <div className="flex-col p-6 bg-x-gray">
      <form onSubmit={handleSubmit}>
        <label className="text-xl title" htmlFor="name">
          {t('yourName')}
        </label>
        <input
          className="block w-full p-2 my-2 mb-8 rounded md:max-w-sm"
          id="contact_form__name"
          name="name"
          required
          type="text"
        />
        <label className="text-xl title" htmlFor="email">
          {t('yourEmail')}
        </label>
        <input
          className="block w-full p-2 my-2 mb-8 rounded md:max-w-sm"
          id="contact_form__email"
          name="email"
          required
          type="email"
        />
        <div className="text-xl title">{t('yourMessage')}</div>
        <textarea
          className="w-full p-2 my-2 mb-4 rounded md:max-w-3xl"
          id="contact_form__message"
          name="message"
          onChange={(event) => setMessage(event.target.value)}
          required
          rows={5}
        />
        <div className="flex flex-col items-stretch justify-between space-y-4 md:space-x-4 md:space-y-0 md:flex-row">
          <button
            className={cx(
              'w-full md:max-w-sm py-4 text-xl title border-x-yellow border rounded',
              {
                'bg-x-yellow text-white': sendEnabled,
                'bg-white text-x-yellow': !sendEnabled,
              }
            )}
            disabled={!sendEnabled}
            type="submit"
          >
            {t('submit')}
          </button>
          {!!error && (
            <div className="flex items-center justify-center flex-1 w-full p-4 text-xl text-white rounded bg-x-red font-display">
              {error}
            </div>
          )}
          {!error && <div className="flex-1" />}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
